import Swiper, { Autoplay, Navigation, Pagination } from "swiper"

Swiper.use([Autoplay, Navigation, Pagination])

export function initHomepageSlider() {
  const sliderSection = document.querySelector(".homepage__slider")

  if (sliderSection) {
    new Swiper(sliderSection.querySelector(".swiper"), {
      slidesPerView: 1,
      watchOverflow: true,
      speed: 500,
      autoplay: {
        delay: 6000
      },
      pagination: {
        el: sliderSection.querySelector(".swiper-pagination"),
        type: "bullets",
        clickable: true
      },
    })
  }
}

export function initShopByCategorySlider() {
  const sliderSection = document.querySelector(".homepage__shop-by-category")
  
    if (sliderSection) {
      setTimeout(() => {

        new Swiper(sliderSection.querySelector(".swiper-container"), {
          slidesPerView: 1.3,
          slidesPerGroup: 1,
          speed: 500,
          spaceBetween: 5,
          breakpoints: {
            1200: {
              slidesPerView: 3.3,
            },
            576: {
              slidesPerView: 2.3,
            }
          },
          watchOverflow: true,
          pagination: {
            el: ".homepage__shop-by-category .homepage-pagination",
            type: "custom",
            renderCustom: function (swiper, current, total) {
              return `<div>0${current} </div> <div>/0${total} </div>`;
            }
          },
          navigation: {
            nextEl: sliderSection.querySelector(".btn-pagination--right"),
            prevEl: sliderSection.querySelector(".btn-pagination--left")
          }
        })
    }, 300)
  }
}

export function initPromotionsSection () {
  function initPromotionsSlider (active_tab) {
    setTimeout(() => {
      if (document.querySelector(".homepage-promotions--swiper-container")) {
        new Swiper(active_tab.querySelector(".homepage-promotions--swiper-container .swiper-container"), {
          slidesPerView: 1,
          spaceBetween: 10,
          watchOverflow: true,
          navigation: {
            nextEl: ".homepage__pagination .btn-pagination--right",
            prevEl: ".homepage__pagination .btn-pagination--left",
          },
          breakpoints: {
            576: {
              slidesPerView: 2,
            }
          }
        })
      }
    })
  }

  function initPromotionsTabSlider() {
    setTimeout(() => {
      const sliderSection = document.querySelector(".homepage__promotions")

      if (sliderSection) {
        let initial_tab = sliderSection.querySelector(".tab-pane.active")
        initPromotionsSlider(initial_tab)

        sliderSection.querySelectorAll("button[data-bs-toggle='tab']").forEach(tab => {
          tab.addEventListener("shown.bs.tab", function () {
            let getId = tab.getAttribute("data-bs-target")
            let active_tab = sliderSection.querySelector(`.tab-pane${getId}`)

            initPromotionsSlider(active_tab)
          }, { once: true })
        })
      }
    }, 300)
  }

  initPromotionsTabSlider()
}

export function initOurProductsTabSlider() {
  function initOurProductsSlider() {
    setTimeout(() => {
      const sliderSection = document.querySelector(".homepage__our-products")

      if (sliderSection) {
        let initial_tab = sliderSection.querySelector(".tab-pane.active")

        initOurProducts(initial_tab)

        sliderSection.querySelectorAll("button[data-bs-toggle='tab']").forEach(tab => {
          tab.addEventListener("shown.bs.tab", function () {
            let getId = tab.getAttribute("data-bs-target")
            let active_tab = sliderSection.querySelector(`.tab-pane${getId}`)

            initOurProducts(active_tab)
          }, { once: true })
        })
      }
    }, 100)
  }

  function initOurProducts(active_tab) {
    setTimeout(() => {
      const sliderSection = active_tab.querySelector(".swiper-container")

      if (sliderSection) {
        new Swiper(sliderSection, {
          slidesPerView: 1.3,
          slidesPerGroup: 1,
          spaceBetween: 15,
          watchOverflow: true,
          breakpoints: {
            1200: {
              slidesPerView: 3
            },
            576: {
              slidesPerView: 2,
              spaceBetween: 30
            }
          }
        })
      }
    })
  }

  initOurProductsSlider()
}

export function initSuggestedTabSlider() {
  function initSuggestedSlider() {
    setTimeout(() => {
      const sliderSection = document.querySelector(".homepage__suggested-products")

      if (sliderSection) {
        let initial_tab = sliderSection.querySelector(".tab-pane.active")

        initSuggestedProducts(initial_tab)

        sliderSection.querySelectorAll("button[data-bs-toggle='tab']").forEach(tab => {
          tab.addEventListener("shown.bs.tab", function () {
            let getId = tab.getAttribute("data-bs-target")
            let active_tab = sliderSection.querySelector(`.tab-pane${getId}`)

            initSuggestedProducts(active_tab)
          }, { once: true })
        })
      }
    }, 300)
  }

  function initSuggestedProducts(active_tab) {
    setTimeout(() => {
      const sliderSection = active_tab.querySelector(".swiper-container")

      if (sliderSection) {
        new Swiper(sliderSection, {
          slidesPerView: 1.3,
          slidesPerGroup: 1,
          spaceBetween: 15,
          watchOverflow: true,
          breakpoints: {
            1200: {
              slidesPerView: 4
            },
            576: {
              slidesPerView: 2.3,
              spaceBetween: 30
            }
          },
          pagination: {
            el: ".homepage__suggested-products .homepage-pagination",
            type: "custom",
            renderCustom: function (swiper, current, total) {
              return `<div>0${current} </div> <div>/0${total} </div>`;
            }
          },
          navigation: {
            nextEl: document.querySelector(".homepage__suggested-products .btn-pagination--right"),
            prevEl: document.querySelector(".homepage__suggested-products .btn-pagination--left")
          }
        })
      }
    })
  }

  initSuggestedSlider()
}

export function initHomepageBlogSlider() {
  const sliderSection = document.querySelector(".homepage__articles")

  if (sliderSection) {
    new Swiper(sliderSection.querySelector(".swiper-container"), {
      slidesPerView: 1.3,
      slidesPerGroup: 1,
      spaceBetween: 10,
      watchOverflow: true,
      breakpoints: {
        1400: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 2.5,
        }
      },
      pagination: {
        el: ".homepage__articles .swiper-pagination",
        type: "bullets",
        clickable: true
      }
    })
  }
}

export function initMainProductSlider() {
  const slider = document.querySelector(".product-page__main-photo")

  if (slider.querySelectorAll(".swiper-slide").length > 0) {
    const swiper = new Swiper(slider, {
      slidesPerView: 1,
      slidesPerGroup: 1,
      watchOverflow: true,
      pagination: {
        el: ".product-page-main-photo-pagination .product-page-pagination",
        type: "bullets",
        clickable: true
      },
    })
  }
}

export function initRelatedProductsSlider() {
  const sliderSection = document.querySelector(".product-page__related")

  if (sliderSection) {
    new Swiper(sliderSection.querySelector(".swiper-container"), {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 10,
      watchOverflow: true,
      breakpoints: {
        1200: {
          slidesPerView: 4,
        },
        576: {
          slidesPerView: 3,
        }
      },
      pagination: {
        el: ".product-page__related .swiper-related-pagination",
        type: "bullets",
        clickable: true
      }
    })
  }
}

export function initArticlePageRelatedSlider() {
  const sliderSection = document.querySelector(".article-page__related-products")

  if (sliderSection) {
    new Swiper(sliderSection.querySelector(".swiper"), {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 10,
      watchOverflow: true,
      navigation: {
        nextEl: document.querySelector(".btn-pagination.btn-pagination--right"),
        prevEl: document.querySelector(".btn-pagination.btn-pagination--left")
      },
      breakpoints: {
        1200: {
          slidesPerView: 4,
        },
        576: {
          slidesPerView: 2,
        }
      }
    })
  }
}