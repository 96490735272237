// Opens and closes the search modal
export function searchInit() {
  const searchModal = document.querySelector(".search__modal")
  const headerSearchIcon = document.querySelector(".header .icon-search")

  if (headerSearchIcon) {
    document.querySelector(".header .icon-search").addEventListener("click", (e) => {
      e.preventDefault()
      searchModal.classList.add("search__modal--open")

      const _searchPanel = searchModal.querySelector(".IZ-select__input input")

      setTimeout(function() {
        _searchPanel.classList.add("IZ-select__input--focused")
        _searchPanel.focus()
      }, 100)
    })

    document.querySelector(".header .icon-close").addEventListener("click", (e) => {
      e.preventDefault()
      searchModal.classList.remove("search__modal--open")
    })
  }
}
