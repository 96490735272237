export const initMainMenu = () => {
  const menuIteml1 = document.querySelectorAll(".main-menu--desktop .menu-item-l1.menu-item-has-children")
  const menuIteml2 = document.querySelectorAll(".main-menu--desktop .menu-item-l2.menu-item-has-children")

  menuIteml1.forEach(item => {
    item.addEventListener("mouseover", () => {
      item.classList.add("menu-item-l1--open")
    })

    item.addEventListener("mouseout", () => {
      item.classList.remove("menu-item-l1--open")
    })
  })

  menuIteml2.forEach(item => {
    item.addEventListener("mouseover", () => {
      item.querySelector(".menu-l3").classList.add("menu-l3--open")
    })

    item.addEventListener("mouseleave", () => {
      item.querySelector(".menu-l3").classList.remove("menu-l3--open")
    })
  })
}

export function responsiveMenu() {
  if (window.innerWidth <= 1201) {
    const menuTrigger = document.querySelector(".header__action--menu")
    const mainMenu = document.querySelector(".main-menu")
    const closeMenu = document.querySelector(".close-mobile")
    const menuSecondLevel = document.querySelectorAll(".menu-item-l1.menu-item-has-children")
    const menuThirdLevel = document.querySelectorAll(".menu-item-l2.menu-item-has-children")

    menuTrigger.addEventListener("click", (e) => {
      e.preventDefault()
      mainMenu.classList.remove("main-menu--desktop", "d-none")
      mainMenu.classList.add("main-menu--mobile", "d-block")
    })

    closeMenu.addEventListener("click", (e) => {
      e.preventDefault()
      mainMenu.classList.remove("main-menu--mobile", "d-block")
      mainMenu.classList.add("main-menu--desktop", "d-none")
    })

    menuSecondLevel.forEach(item => {
      item.querySelector(".line-icon").addEventListener("click", (e) => {
        e.preventDefault()
        item.classList.toggle("active")
        item.querySelectorAll(".menu-l2").forEach(list => {
          list.classList.toggle("active")
        })
      })
    })

    menuThirdLevel.forEach(item => {
      item.querySelector(".line-icon").addEventListener("click", (e) => {
        e.preventDefault()
        item.classList.toggle("active")
        item.querySelectorAll(".menu-l3").forEach(list => {
          list.classList.toggle("active")
        })
      })
    })
  }
}

export function changeMenuBannersOnHover() {
  if (document.querySelector(".main-menu-banners")) {
    const categoryTitles = document.querySelectorAll(".menu-category-title > a")
    const leftImages = [...document.querySelectorAll("img.menu-left-image")]
    const rightImages = [...document.querySelectorAll("img.menu-right-image")]

    for (let i = 0; i < categoryTitles.length; i++) {
      categoryTitles[i].addEventListener("mouseenter", function(e) {

        if (this.dataset.leftBanner)
          leftImages[i].setAttribute("src", this.dataset.leftBanner)

        if (this.dataset.rightBanner)
          rightImages[i].setAttribute("src", this.dataset.rightBanner)
      })
    }
  }
}